.buttons-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.header-publicacao-fase-button {}


@media (max-width: 576px) {
    .buttons-container {
        justify-content: flex-end;
    }

    .header-publicacao-fase-button {
        margin-top: 6px;
    }
}
