.offcanvas-model {
  position: absolute;
  top: 2px;
  right: -350px;
  width: 350px;
  transition: all 0.15s ease-in-out;
  opacity: 1;
}

.offcanvas-model.open {
  right: 3px;
  opacity: 1;
}

.offcanvas-model-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.offcanvas-model-content {
  padding: 20px;
  height: calc(80vh - 4px);
  overflow-y: scroll;
  background-color: rgba(240, 248, 255, 0.597);
  backdrop-filter: blur(10px);
  border-right: 0;
}

.close-button {
  border: none;
  background-color: transparent;
  font-size: 16px;
  cursor: pointer;
}
