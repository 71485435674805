.magnifier {
  transition: all .33s ease;
}

.magnifier::before {
  content: "Clique para ampliar";
  background-color: white;
  border-radius: 40px;
  color: black;
  padding: 1em;
  font-size: clamp(.5rem, .75vw, .85rem);
  position: absolute;
  /* top: 37%; */
  top: 10%;
  left: 20%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease;
  opacity: 0;
}

.magnifier:hover {
  opacity: 0.8;
}

.magnifier:hover::before {
  opacity: 0.8;
}

.selected-card {
  border-width: 1px !important;
}